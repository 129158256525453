<template>
    <div style="height:50px;width: 330px;margin: 0 auto;" v-if="is_login == 1">
        <!--   登录名称    -->
        <div style="width: auto;float: right">
            <el-dropdown @command="login_out" >
                <div class="hand op sc" style="width: 100%;height: auto;display: flex;text-align: right">
                    <img :src="user.headIco" style="width: 26px;height: 26px;margin:12px 0;border-radius: 50%;">
                    <div style="color: #031F88;height: 50px;line-height: 50px;font-size: 15px;max-width: 210px">{{ user.nickName }}</div>
                    <div style="line-height: 50px;"><i class="el-icon-caret-bottom el-icon--right" style="color: #031F88 "></i></div>
                </div>
                <!--  下拉菜单 -->
                <el-dropdown-menu slot="dropdown" style="margin-top: -5px;">
                    <div style="width: 130px;min-height: 180px;background: #FFFFFF;">
                        <div v-if="user.userType != 3" style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="circle">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self1.png" style="width: 15px;height: 16px;">
                                </div>
                                <div v-if="user.userType == 1" style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                    我的主页
                                </div>
                                <div v-if="user.userType == 2" style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                    机构主页
                                </div>
                            </div>
                        </div>
                        <div v-if="user.userType == 3" style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="circle">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self1.png" style="width: 15px;height: 16px;">
                                </div>
                                <div  style="height: 19px;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                    子空间
                                </div>
                            </div>
                        </div>
                        <div  v-if="user.userType == 1" style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;" @click="toAuth">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self2.png" style="width: 15px;height: 16px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                    个人档案
                                </div>
                            </div>
                        </div>
                        <div v-if="user.userType == 2" style="display: flex;justify-content: center">
                            <div class="hand op sc" @click="a('/auth')" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self2.png" style="width: 15px;height: 16px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10.5px;">
                                    机构认证
                                </div>
                            </div>
                        </div>
                        <div v-if="user.userType != 3" style="display: flex;justify-content: center" @click="a('/safety')">
                            <div class="hand op sc" style="height: 36px;width:114px;display: flex;justify-content: center;align-items: center;" >
                                <div style="height: 22px;width: 22px;">
                                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/head/setup.png" style="height: 22px;width: 22px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 8px;">
                                    安全中心
                                </div>
                            </div>
                        </div>
                        <div v-if="user.userType != 3" style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height:36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="b('/vipPage')">
                                <div style="width: 18px;height: 17px;">
                                    <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                    会员中心
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center">
                            <div v-if="user.isVip > 0" class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="isSpaceSizeWin = true">
                                <div style="width: 18px;height: 17px;">
                                    <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                    空间扩容
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center">
                            <div v-if="user.userType == 2 && user.isVip > 0" class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="isSubAccountWin = true">
                                <div style="width: 18px;height: 17px;">
                                    <img src="~@/assets/imgs/index/self4.png" style="width: 18px;height: 17px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 10px;">
                                    席位扩容
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="a('/index/ruleCenter/' + 0)">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self5.png" style="width: 15px;height: 16px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                    规则中心
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;" @click="a('/index/help/' + 0)">
                                <div style="width: 15px;height: 16px;">
                                    <img src="~@/assets/imgs/index/self6.png" style="width: 15px;height: 16px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                    帮助中心
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center">
                            <div class="hand op sc" style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;position: relative" @click="switchAccounts">
                                <div style="width: 15px;height: 15px;">
                                    <img src="~@/assets/imgs/index/qhzh.png" style="width: 15px;height: 15px;">
                                </div>
                                <div style="height: auto;width: calc(100% - 48px);font-size: 15px;font-weight: bold;cursor: pointer;margin-left: 11px;">
                                    切换账号
                                </div>
                            </div>
                        </div>
                        <div style="position: relative;display: flex;justify-content: center">
                            <div style="height: 36px;width: 114px;display: flex;justify-content: center;align-items: center;">
                                <el-dropdown-item command="/index/index" >退出登录</el-dropdown-item>
                            </div>
                        </div>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div style="width: 40px;height: 20px;margin:16px 10px 12px 20px;float:right;" @click="message">
            <el-badge :hidden="$store.state.systemCount==0" :max="99" :value="$store.state.systemCount" class="item" >
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/icon.png" style="width: 25px;height: 19px;">
            </el-badge>
        </div>
        <el-dialog :visible.sync="showLogin" :close-on-click-modal="false" :append-to-body="isAppendToBody" width="800px" class="begin">
            <AccountSelection :userOptions="userOptions"  :onClose="function (){showLogin = false}" ></AccountSelection>
        </el-dialog>
        <!--扩容-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isSpaceSizeWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <spaceSizeWin :type="3"/>
            </el-dialog>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isSubAccountWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <subAccountWin :type="3"/>
            </el-dialog>
        </div>
        <!--支付成功-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isPaySuccessWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <pay-success-win />
            </el-dialog>
        </div>
    </div>
</template>
<script>
import AccountSelection from "./AccountSelection";
import spaceSizeWin from "./Pay/vip/expand/spaceSizeWin";
import subAccountWin from "./Pay/vip/expand/subAccountWin.vue";
import paySuccessWin from "./Pay/vip/paySuccessWin";
export default {
    name: "AvatarMenu",
    components: {AccountSelection,paySuccessWin, spaceSizeWin, subAccountWin},
    data() {
        return {
            // 切换账号
            isAppendToBody:true,
            userOptions:[],
            showLogin:false,
            isSpaceSizeWin:false,
            isPaySuccessWin:false,
            isSubAccountWin:false,
        };
    },

    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        },
    },

    created:function(){
        var that = this;
        this.$EventBus.$EventBus.$off("payMsg")
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if(isSpaceSizeWin == true || isSubAccountWin == true){
                    that.isSpaceSizeWin = false;
                    that.isSubAccountWin = false,
                        that.isPaySuccessWin = true;
        }
            })
        })
    },

    mounted() {

    },
    methods: {
        login_out() {
            localStorage.token = '';
            localStorage.uuid = '';
            localStorage.removeItem('payParams');
            this.$router.push({ path: '/' });
            this.$store.commit("login_out");
            this.$store.state.subList = [];
            this.showLogin = false;

        },
        // 我的主页
        circle(){
            var that = this;
            if(that.user.userType === 1){
                that.utils.a('/circle/mySpace/' + that.user.uuid)
            }else if (that.user.userType === 2 || that.user.userType === 3){
                that.utils.a('/circle/organSpace/' + that.user.uuid)
            }
        },
        // 个人档案
        toAuth(){
            var that = this;
            that.$router.push('/archives')
        },
        //切换账号
        switchAccounts(){
            var that = this;
            that.newApi.getLoginCodeList({}).then(res=>{
                if(res.isSuccess == 1){
                    that.userOptions = res.data;
                    that.showLogin = true;
                }
            })
        },
        // 通知消息
        message(){
            window.open('/message/index')
        },
    },
};
</script>
<style scoped>
/deep/ .el-dialog__header{
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
/deep/ .dialog-container .el-dialog {
    border-radius: 10px !important;
}
</style>
